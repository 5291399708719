import { NavItem } from "@/app/types/nav";

export const navItems: NavItem[] = [
  {
    title: "game",
    options: [{ title: "Preview", path: "/market/gameassets" }],
  },
  {
    title: "hooligans",
    options: [
      {
        title: "Shop",
        path: "/market",
        tab: "Hooligans",
      },
      {
        title: "Lore",
        path: "https://www.mypethooligangame.com/about",
        target: "_blank",
        rel: "noreferrer noopener",
      },
    ],
  },
  {
    title: "zuckbots",
    options: [
      {
        title: "Shop",
        path: "/market",
        tab: "Zuckbots",
      },
      // {
      //   title: "Lore",
      //   path: "https://www.mypethooligangame.com/zuckbots",
      //   target: "_blank",
      //   rel: "noreferrer noopener",
      // },
    ],
  },
  {
    title: "socials",
    options: [
      {
        title: "Instagram",
        path: "https://www.instagram.com/mypethooligan/",
        target: "_blank",
        rel: "noreferrer noopener",
      },
      {
        title: "X (Twitter)",
        path: "https://twitter.com/mypethooligan",
        target: "_blank",
        rel: "noreferrer noopener",
      },
      {
        title: "Discord",
        path: "https://discord.gg/mypethooligan",
        target: "_blank",
        rel: "noreferrer noopener",
      },
    ],
  },
];
